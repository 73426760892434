const routes = [
  {
    path: "/",
    name: "deflaut",
    meta: {title: '首页'},
    component: () => import('@/views/pc/common/index.vue')
  },
  {
    path: "/login-entry",
    name: "login-entry",
    meta: {title: '登录'},
    component: () => import('@/views/pc/common/loginEntry.vue')
  },
  {
    path: "/login-pass",
    name: "login-pass",
    meta: {title: '账号密码登录'},
    component: () => import('@/views/pc/common/loginPass.vue')
  },
  {
    path: '/register',
    name: 'register',
    meta: {title: '注册'},
    component: () => import('@/views/pc/common/register.vue')
  },
  {
    path: "/index",
    name: "index",
    meta: {title: '首页'},
    component: () => import('@/views/pc/common/index.vue')
  },
  {
    path: "/demandList",
    name: "demand-list",
    meta: {title: '需求列表'},
    component: () => import('@/views/pc/common/demandList.vue')
  },
  {
    path: "/talentList",
    name: "talent-list",
    meta: {title: '人才列表'},
    component: () => import('@/views/pc/common/talentList.vue')
  },
  {
    path: "/talentListDetails/:id",
    name: "talent-list-details",
    meta: {title: '人才列表详情页面'},
    component: () => import('@/views/pc/common/talentListDetails.vue')
  },
  {
    path: "/caseList",
    name: "case-list",
    meta: {title: '案例列表'},
    component: () => import('@/views/pc/common/caseList.vue')
  },
  {
    path: "/caseListDetails/:id",
    name: "case-list-details",
    meta: {title: '案例列表详情页面'},
    component: () => import('@/views/pc/common/caseListDetails.vue')
  },
  {
    path: "/consult-entry",
    name: "consult-entry",
    meta: {title: '咨询入口'},
    component: () => import('@/views/pc/common/consultEntry.vue')
  },
  {
    path: "/join-entry",
    name: "join-entry",
    meta: {title: '签约接单'},
    component: () => import('@/views/pc/common/joinEntry.vue')
  },
  {
    path: "/order/:tradenum",
    name: "oreder-payment",
    meta: {title: '订单支付'},
    component: () => import('@/components/pc/common/blocks/orderpayment.vue')
  },
  {
    path: "/my-demand-detail/:id",
    name: "my-demand-detail",
    meta: {title: '我的需求详情'},
    component: () => import('@/components/pc/common/blocks/myDemandDetail.vue')
  },
  {
    path: "/modify-my-unchecked-demand/:id",
    name: "modify-my-unchecked-demand",
    meta: {title: '修改我的未审核需求'},
    component: () => import('@/components/pc/common/blocks/modifyMyUncheckedDemand.vue')
  },
  {
    path: "/order-detail/:tradenum",
    name: "order-detail",
    meta: {title: '订单详情'},
    component: () => import('@/components/pc/common/blocks/orderDetail.vue')
  },
  {
    path: "/order-detail-admin/:tradenum",
    name: "order-detail-amin",
    meta: {title: '订单详情'},
    component: () => import('@/components/pc/adminSite/tabs/orderDetail.vue')
  },
  {
    path: "/modify-order-amount/:tradenum",
    name: "modify-order-amount",
    meta: {title: '修改订单价格'},
    component: () => import('@/components/pc/adminSite/tabs/modifyOrder.vue')
  },
  {
    path: "/products",
    name: "products",
    meta: {title: '产品列表'},
    component: () => import('@/views/pc/common/productList.vue')
  },
  // {
  //   path: "/post-product",
  //   name: "post-product",
  //   meta: {title: '产品发布'},
  //   component: () => import('@/components/pc/common/blocks/productPublish.vue')
  // },
  {
    path: "/productDetail/:prtid",
    name: "productDetail",
    meta: {title: '产品详情'},
    component: () => import('@/components/pc/common/blocks/productDetail.vue')
  },
  {
    path: "/myProductDetailFreelancer/:prtid",
    name: "myProductDetail",
    meta: {title: '我的产品详情'},
    component: () => import('@/components/pc/freelancerSite/main/myProductDetail.vue')
  },
  {
    path: "/modify-my-product-freelancer/:prtid",
    name: "productModify",
    meta: {title: '修改我的产品'},
    component: () => import('@/components/pc/freelancerSite/innerPage/modifyMyProduct.vue')
  },
  {
    path: "/productDetailAdmin/:prtid",
    name: "productDetailAdmin",
    meta: {title: '产品详情'},
    component: () => import('@/components/pc/adminSite/tabs/productDetail.vue')
  },
  {
    path: "/checkProduct/:prtid",
    name: "checkProduct",
    meta: {title: '审核产品'},
    component: () => import('@/components/pc/adminSite/tabs/checkProduct.vue')
  },
  {
    path: "/chat",
    name: "chat",
    meta: {title: 'ai聊天'},
    component: () => import('@/components/pc/common/blocks/chat.vue')
  },
  {
    path: "/demandAssistant",
    name: "demandAssistant",
    meta: {title: 'ai聊天'},
    component: () => import('@/components/pc/common/blocks/demandAssistant.vue')
  },
]

export default routes
