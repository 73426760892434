import router from './router'
import store from './store'
import axios  from 'axios'
import NProgress from 'nprogress' // progress bar
import { setToken, getToken } from '@/utils/token' // get token from cookie
import getPageTitle from '@/utils/get-page-title'
import { wxloginRequest } from '@/network/api/login'
import { userPhoneLoginBindWx ,checkAccessDemandDetails,userPhoneLoginBindWxMobile } from '@/network/api/user'
// import { loginLocalStorage } from "@/utils/storage"
import { Message } from 'element-ui'
import { fetchRoutersRequest } from "@/network/api/login"
// import { loginLocalStorage, loginSessionStorage } from "@/utils/storage"
import { processFetchedRoutes } from "@/utils/processFetchedRoutes"
import { routesSessionStorage } from "@/utils/storage"
import isMobile from '@/utils/device'
import isWeixin from '@/utils/device'

// 微信认证相关
const appid = 'wx6ebb7c4519379a3a';
const secret = 'your-secret';


NProgress.configure({ showSpinner: false }); // NProgress Configuration


router.beforeEach(async(to, from, next) => {

  // start progress bar
  NProgress.start()
  // set page title
  document.title = getPageTitle(to.meta.title)
  // determine whether the user has logged in
  const hasToken = !!getToken()


  let device_type = ''
  if (isMobile() || isWeixin()) { device_type = 'mobile' } else { device_type = 'pc' }

  let link = window.location.href

  // 解码state
  let url = new URL(link)
  let params = new URLSearchParams(url.search)
  let state = params.get('state')
  // 适用于mobile端，product标准化产品
  let stateArr = []
  if(state != null &&state != undefined) {
    stateArr = state.split("/")
    console.log(stateArr,"look stateArr")
  }  
  


  let stateDemandPay = []
  if (state != null && state != undefined) {
    stateDemandPay = state.split("/")
  }

  // 如果没有token，表示未登录，访问需登录页面前应当记住目标路径
  // if (!hasToken && link.indexOf('code') >= 0 && from.path!='/home' && state == '') {
  //   let code = link.split("?")[1];
  //   code = code.substring(5, code.indexOf('&'))
  //   store.commit('user/SET_CODE', code)

  //   // 判断state里面是否携带prePath
  //   if(link.split('%2F').length > 1) {
  //     let s1 = link.split('%2F')[1]
  //     let s2 = link.split('%2F')[2].replace('#/','')
  //     let s3 = s1+'/'+s2
  //     if(s3 != '') {
  //       store.commit("SET_PRE_PATH",s3)
  //     }
  //   }

  //   try {
  //     const params = { 'code': code, 'device_type': device_type }
  //     const response = await wxloginRequest(params)
  //     if(device_type == 'mobile') {
  //       alert('登录成功')
  //     }
  //     Message({ message: '登录成功', type: 'success' })   
  //     setToken(response.token)  
  //     store.commit("SET_LOGIN")
  //     store.commit('user/SET_TOKEN', response.token)
  //     const userInfo = {
  //       'uid': response.uid,
  //       'username': response.username,
  //     }
  //     store.commit('user/SET_INFO', userInfo)
  //     // loginLocalStorage(userInfo)
  //   } catch (error) {
  //     console.error(error)
  //   }

  //   try {
  //     const data = {
  //       'device_type': device_type
  //     }
  //     const response = await fetchRoutersRequest(data)
  //     const status = response.status
  //     if(status == 200){
  //       const fetchedRoutes = response.data.routers
  //       let parsedFetchedRoutes = JSON.parse(fetchedRoutes)
  //       processFetchedRoutes(parsedFetchedRoutes)
  //       router.selfAddRoutes(parsedFetchedRoutes)
  //       store.commit('router/SET_ROUTES', parsedFetchedRoutes)
  //       routesSessionStorage(fetchedRoutes)
  //     }else {
  //       const message = response.data.msg
  //       Message({ message: message, type: 'error' })
  //     }
  //   } catch (error) {
  //     console.error(error)
  //   }

  //   let prePath = store.state.prePath
  //   if ( prePath == '') {
  //     console.log('手机端的空path')
  //     next({ path: '/home', query: {} })
  //   } else {
  //     let toPath = prePath
  //     let url = ''
  //     if(device_type == 'mobile') {
  //       if(toPath != '') {
  //         if(toPath.includes('-detail')) {
  //           url = toPath.replace('demand-detail','demand')
  //         }else {
  //           url = toPath
  //         }
  //       }
  //     }
  //     store.commit("CLEAR_PRE_PATH")
  //     router.push(url)
  //   }
  // }


  // 如果前端有token留存，检查是否
  if (hasToken && device_type == 'pc') {
    if (to.path === '/login-entry') {
      // if is logged in, redirect to the home page
      next({ path: '/index' })
      NProgress.done();
    } else if (to.path === '/login-pass') {
      // if is logged in, redirect to the home page
      next({ path: '/index' })
      NProgress.done();
    } else if (to.path === '/register') {
      next({ path: '/index'})
      NProgress.done()
    }else {
      next();
      NProgress.done()
    }
  } else if (hasToken && device_type == 'mobile') {
    if (to.path === '/index') {
      // if is logged in, redirect to the home page
      next({ path: '/demandPublish' })
      NProgress.done();
    }else if(to.path === '/' && stateDemandPay[0] === 'dawnswimdemandpay'){
      next({ path: '/demandPayOrder'})
      NProgress.done()
    }else if(to.path === '/') {
      next({ path: '/demandList'})
      NProgress.done()
    }else if (to.path === '/login-entry') {
      next({ path: '/demandList'})
      NProgress.done();
    } else if (to.path === '/login-pass') {
      next({ path: '/demandList'})
      NProgress.done()
    } else {
      next()
      NProgress.done()
    }
  }else {
    let str1 = to.path
    let arr1 = str1.split('/')
    let t = arr1[1]
    if ((t == 'demand-detail' || t == 'demand')) {
      if(device_type == 'pc'){
        next({ path: '/login-entry', query: { redirect: to.fullPath } })
      }
      if(device_type == 'mobile') {
        next({ path: '/', query: { redirect: to.fullPath } })
      }
      
      NProgress.done()
    } else if (t == 'home') {
      next({ path: '/', query: { redirect: to.fullPath }})
      NProgress.done()
    } else {
      next()
      NProgress.done()
    }
  }

  // 微信支付
  if(hasToken && device_type == 'mobile' && to.path === '/' && stateArr[1] == 'product') {
    let code = link.split("?")[1];
    code = code.substring(5, code.indexOf('&'))
    store.commit('user/SET_CODE', code)

    next( {path: "/productPayOrder", query: {'product_id': stateArr[2] ,'type':stateArr[3], "code": code } })
    NProgress.done()
  }

})

router.afterEach((to,from) => {
  const hasToken = !!getToken()
  let link = window.location.href
  let device_type = ''
  if (isMobile() || isWeixin()){ device_type = 'mobile' } else { device_type = 'pc' }

  // 解码state
  let url = new URL(link)
  let params = new URLSearchParams(url.search)
  let state = params.get('state')

  // 如果没有token，表示未登录，访问需登录页面前应当记住目标路径
  if (!hasToken && isMobile && link.indexOf('/index') >= 0) {
    store.commit("SET_PRE_PATH", '/demandPublish')
  }

  // 截取路径的基础url
  let arr = []
  let str = to.query.redirect
  if(str) {
    arr = str.split('/')
  }
  let t = arr[1]

  // 还需一个判断，是否是用demnad路径进入的
  if (!hasToken && (t == 'demand-detail' || t == 'demand') ) {
    store.commit("SET_PRE_PATH", `${to.query.redirect}`)
  }


  // finish progress bar
  NProgress.done()
})
