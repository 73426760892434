import { loginRequest, getInfo } from '@/network/api/login'
import { logout } from '@/network/api/logout'
import { getToken, setToken, removeToken } from '@/utils/token'
import router, { resetRouter } from '@/router'

const state = {
  code: '',
  token: getToken(),
  username: '',
  uid: '',
  avatar: '',
  roles: [],
  stsToken: {},
  wxStatus: false,
  currentWx: false,
  demandListScrollPage:0,
  // scrollTop: 0
  
}

const mutations = {
  SET_CODE: (state, code) => {
    state.code = code
  },
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_NAME: (state, name) => {
    state.username = name
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles
  },
  SET_STSTOKEN: (state, stsToken) => {
    state.stsToken = stsToken;
  },
  SET_WXSTATUS: (state, wxStatus) => {
    state.wxStatus = wxStatus;
  },
  SET_CURRENTWX: (state,currentWx) => {
    state.currentWx = currentWx;
  },

  // SET_SCROLL_TOP:(state,scrollTop) => {
  //   state.scrollTop = scrollTop
  // },

  SET_DEMANDLISTSCROLLPAGE:(state,demandListScrollPage) => {
    state.demandListScrollPage = demandListScrollPage;
  },

  SET_INFO: (state, userInfo) => {
    state.uid = userInfo.uid
    state.username = userInfo.username
  },

  CLEAR_USER_INFO: (state) => {
    state.code = ''
    state.token = ''
    state.username = ''
    state.id = ''
    state.avatar = ''
    state.roles = []
    state.stsToken = {}
  }
}

const actions = {
  // user login
  // login({ commit }, userInfo) {
  //   const { username, password } = userInfo
  //   return new Promise((resolve, reject) => {
  //     loginRequest({ username: username.trim(), password: password }).then(response => {
  //       const { data } = response
  //       commit('SET_TOKEN', data.token)
  //       setToken(data.token)
  //       resolve()
  //     }).catch(error => {
  //       reject(error)
  //     })
  //   })
  // },

  // get user info
  // getInfo({ commit, state }) {
  //   return new Promise((resolve, reject) => {
  //     getInfo(state.token).then(response => {
  //       const { data } = response

  //       if (!data) {
  //         reject('Verification failed, please Login again.')
  //       }

  //       const { roles, name, avatar, introduction } = data

  //       // roles must be a non-empty array
  //       if (!roles || roles.length <= 0) {
  //         reject('getInfo: roles must be a non-null array!')
  //       }

  //       commit('SET_ROLES', roles)
  //       commit('SET_NAME', name)
  //       commit('SET_AVATAR', avatar)
  //       commit('SET_INTRODUCTION', introduction)
  //       resolve(data)
  //     }).catch(error => {
  //       reject(error)
  //     })
  //   })
  // },

  // user logout
  // logout({ commit, state, dispatch }) {
  //   return new Promise((resolve, reject) => {
  //     logout(state.token).then(() => {
  //       commit('SET_TOKEN', '')
  //       commit('SET_ROLES', [])
  //       removeToken()
  //       resetRouter()

  //       // reset visited views and cached views
  //       // to fixed https://github.com/PanJiaChen/vue-element-admin/issues/2485
  //       dispatch('tagsView/delAllViews', null, { root: true })

  //       resolve()
  //     }).catch(error => {
  //       reject(error)
  //     })
  //   })
  // },

  // remove token
  // resetToken({ commit }) {
  //   return new Promise(resolve => {
  //     commit('SET_TOKEN', '')
  //     commit('SET_ROLES', [])
  //     removeToken()
  //     resolve()
  //   })
  // },

  // dynamically modify permissions
  // async changeRoles({ commit, dispatch }, role) {
  //   const token = role + '-token'

  //   commit('SET_TOKEN', token)
  //   setToken(token)

  //   const { roles } = await dispatch('getInfo')

  //   resetRouter()

  //   // generate accessible routes map based on roles
  //   const accessRoutes = await dispatch('permission/generateRoutes', roles, { root: true })
  //   // dynamically add accessible routes
  //   router.addRoutes(accessRoutes)

  //   // reset visited views and cached views
  //   dispatch('tagsView/delAllViews', null, { root: true })
  // }

}



export default {
  namespaced: true,
  state,
  mutations,
  actions
}
